.container {
    overflow-x: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}