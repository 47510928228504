.inputStyle {
  width: 50% !important;
  height: 45px;
 
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  border-bottom: 1px #e1e1e1 solid;
}
