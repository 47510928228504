.patternOne{
    position: absolute;
        left: 58.62%;
        right: -7.96%;
        top: 82.85%;
        bottom: -166.59%;
    
        background: #1C3454;
        transform: matrix(0.07, -1, 1, 0.07, 0, 0);
}
.patternTwo{
    position: absolute;
        left: 59.64%;
        right: -8.99%;
        top: 76.65%;
        bottom: -160.39%;
    
        background: #040D1A;
        transform: matrix(0.07, -1, 1, 0.07, 0, 0);
}

.homeBanner{
    background-image: url("../../asset/homeBanner.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
   
    height: 600px;
}
.testimonialBanner{
    background-image: url("../../asset/testimonialBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 60px 40px;
        height: 600px;
}


.homeImage>img {
    max-width: 150px;
    height: 4.5rem;
    padding: 0 15px;
    object-fit: cover;
    
}

img:last-of-type {
    padding-left: 0;
}

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 4rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

@media screen and (max-width: 800px) {
    .homeBanner {
        background-image: url("../../asset/homeMobileBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 600px;
    }

    .testimonialBanner {
        background-image: url("../../asset/testimonialBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px 20px;
        height: 430px;
    }
}

@media screen and (max-width: 500px) {
    .homeBanner {
        background-image: url("../../asset/homeMobileBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 350px;
    }

    .testimonialBanner {
        background-image: url("../../asset/testimonialMobileBanner.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px 30px;
        height: 500px;
    }

    .homeImage>img {
        max-width: 100px;
        height: 3.5rem;
        padding: 0 15px;
        object-fit: cover;

    }
}