
.image > img {
    max-width: 150px;
    height: 4rem;
    padding: 0 15px;
    object-fit: cover;
    filter: grayscale(100%);
}

img:last-of-type {
    padding-left: 0;
}

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 4rem;
}

.wrapper {
    position: absolute;
    display: flex;
    align-items: flex-start !important;
    justify-content: start ;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
    align-items: flex-start !important;
    justify-content: start;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}